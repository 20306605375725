/* CONTATO */

.contact {
  margin-top: 4rem;
  padding-top: 2rem;
  background-color: var(--cdm-secundaria);
}

.contact__info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact__logo {
  max-width: 200px;
}

.contact__subtitle__container {
  text-align: center;
  margin: 1.5rem 1.5rem 0 1.5rem;
}

.contact__subtitle {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--cdm-branco);
  margin-bottom: 0.5rem;
}

.contact__subtitle span {
  color: var(--cdm-principal);
  font-weight: 700;
}

.contact__p {
  font-size: 1rem;
  color: var(--cdm-branco);
}

.contact__cliente__img {
  display: none;
}

@media screen and (min-width: 768px) {
  .contact {
    padding: 2rem 4rem 10rem 4rem;
  }

  .contact__info {
    flex-direction: row;
    justify-content: center;
  }

  .contact__subtitle__container {
    text-align: center;
    margin-bottom: 2rem;
  }

}

@media screen and (min-width: 1025px) {
  .contact {
    display: flex;
    background-color: var(--cdm-principal);
    padding: 0 4rem;
    height: 92vh;
  }

  .contact__info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .contact__info {
    margin-top: 2rem;
  }

  .contact__logo {
    display: none;
    max-width: 30%;
    margin-bottom: 0;
  }

  .contact__subtitle__container {
    margin-top: 7rem;
    margin-bottom: 0rem;
  }

  .contact__subtitle {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.7rem;
  }

  .contact__subtitle span {
    color: var(--cdm-secundaria);
  }

  .contact__p,
  .form__container {
    font-size: 1.313rem;
  }

  .contact__cliente__img {
    display: block;
    width: 400px;
  }

  .form_grid {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


@media screen and (min-width: 1440px) {
  .contact {
    padding: 0 10rem;
    gap: 10rem;
    height: 100vh;
  }

  .contact__subtitle__container {
    margin-top: 4rem;
    margin-bottom: 0rem;
  }

  .contact__subtitle {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 0.7rem;
  }

  .contact__info {
    margin-top: 3rem;
  }

  .contact__p {
    display: block;
  }

  .contact__p,
  .form__container {
    font-size: 1.313rem;
  }

  .form_grid {
    margin-bottom: 1rem;
  }

  .contact__cliente__img {
    display: block;
    width: 450px;
  }
}

@media screen and (min-width: 1920px) {

  .contact__subtitle__container {
    margin-top: 8rem;
  }
}