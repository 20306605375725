.associado-container {
    padding: 4.7rem 0 4.7rem 0;
    background-color: var(--cdm-secundaria);
    min-height: 72.8vh;
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    color: var(--cdm-secundaria);
    text-align: center;
}

.fundo-div {
    background-color: var(--cdm-branco);
    padding: 2rem;
}

.associado-titulo {
    font-weight: 700;
    text-align: center;
}

.associado-contratar-meios,
.client-service-locations {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-width: 50%;
    text-align: center;
}

.meios p,
.location p {
    font-weight: 500;
}

.meios a {
    text-decoration: none;
    color: var(--cdm-secundaria);
}

.border-right {
    border-right: 2px solid var(--cdm-secundaria);
}

.associado-phone,
.location-phone {
    display: flex;
    align-items: center;
    gap: 0.16rem;
}

.meios,
.location {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.meios a i,
.location-phone-litoral i {
    margin-right: 0.16rem;
}

.meios a,
.meios h5 {
    text-decoration: underline;
    text-underline-offset: 3px;

}

.wpp-logo,
.fa-icon {
    width: 20px;
}

.location {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.associado-institutional {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
}

.institutional-header {
    justify-content: center;
}

.institutional-phone i {
    margin-right: 0.16rem;
}


/* HOVER */

.meios a:hover {
    cursor: pointer;
}

@media screen and (min-width: 682px) {
    .associado-container {
        padding: 4.9rem 0 5.6rem 0;
    }
}

@media screen and (min-width: 768px) {
    .associado-container {
        padding-top: 5.6rem;
    }
}

@media screen and (min-width: 1025px) {
    .associado-container {
        padding: 9.9rem 4rem 9.2rem 4rem;
        background-color: var(--cdm-secundaria);
        min-height: auto;
    }

    .associado-institutional {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}


@media screen and (min-width: 1187px) {
    .associado-container {
        padding: 9.9rem 4rem 10.2rem 4rem;
        background-color: var(--cdm-secundaria);
        min-height: auto;
    }
}

@media screen and (min-width: 1440px) {
    .associado-container {
        padding: 11rem 25rem 9rem 25rem;
        background-color: var(--cdm-secundaria);
        min-height: auto;
    }


}