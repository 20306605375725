/* CANAIS */

.canais {
    margin:2rem;
    display: flex;
    text-align: center;
    gap: 2rem;
}

.canais__links {
    display: flex;
    justify-content: center;
    max-width: 25rem;
}

.canais__links img {
    width: 30%;
    margin-bottom: 0.5rem;
}

.canais__links button {
    display: block;
    text-decoration: none;
    color: var(--cdm-secundaria);
    font-size: 1rem;
    font-weight: 600;
    width: 100%;
    padding: 1rem;
    border: 0.1rem solid var(--cdm-secundaria);
    border-radius: 0.7rem;
}

/* HOVER */

.canais__links button:hover,
.canais__links:hover {
    transform: scale(1.1);
    color: var(--cdm-branco);
    background-color: var(--cdm-secundaria);
    border: 0.1rem solid var(--cdm-branco);
}

@media screen and (min-width: 768px) {
    .canais {
        justify-content: center;
    }

    .canais__links p {
        font-size: 1.1rem;
    }
}

@media screen and (min-width: 1025px) {
    .canais__links p {
        font-size: 1.2rem;
    }
}

@media screen and (min-width: 1440px) {
    .canais__links p {
        font-size: 1.3rem;
    }
}