/* CONTATO */

.atendimento {
    margin-top: 4rem;
    background-color: var(--cdm-principal);
}

.atendimento__title {
    padding: 2rem 0 1rem 0;
    width: 100%;
    margin-bottom: 2rem;
    background-color: var(--cdm-secundaria);
}

.atendimento__title h2 {
    color: var(--cdm-branco);
    font-size: 1.5rem;
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: 0.3rem;
    line-height: 2rem;
    text-decoration-color: var(--cdm-principal);
    text-align: center;
    margin: 0 2rem;
}

@media screen and (min-width: 768px) {
    .atendimento {
        margin-top: 4.9rem;

    }

    .atendimento__title h2 {
        font-size: 1.7rem;
        text-underline-offset: 0.4rem;
        line-height: 2rem;
        padding-top: 0.5rem;
    }
}

@media screen and (min-width: 1025px) {

    .atendimento {
        margin-top: 9.5rem;

    }
    .atendimento__title h2 {
        font-size: 1.8rem;
        text-underline-offset: 0.4rem;
        line-height: 2rem;
        padding-top: 0.5rem;
    }
}

@media screen and (min-width: 1440px) {
    .atendimento {
        margin-top: 10.5rem;

    }

    .atendimento__title h2 {
        font-size: 2.3rem;
        text-underline-offset: 0.5rem;
        line-height: 2rem;
        padding-top: 1rem;
    }
}