/* BENEFITS */

.benefits {
    margin: 1rem 2rem;
    font-size: 1rem;
    line-height: 1.4rem;
}

.benefits__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--cdm-secundaria);
}

.benefits__title h1 {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.7rem;
    text-align: initial;
}

.benefits__title h1 span {
    font-size: 1.7rem;
    color: var(--cdm-principal);
}

.benefits h3,
.benefits span {
    margin-bottom: 1.5rem;
}

.benefits h3 {
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    color: var(--cdm-branco);
}

/* BENEFICIOS CARROSSEL */
.benefits .swiper {
    margin-bottom: 3rem;
}

.benefits .swiper img {
    width: 100%;
}

/* FIM DO CARROSEL */

.benefits___container {
    align-items: center;
}

.benefits__content {
    padding: 1.5rem;
    border-right: 3px solid var(--cdm-secundaria);
    border-bottom: 3px solid var(--cdm-secundaria);
    border-top: 3px solid var(--cdm-secundaria);
    border-radius: 1rem;
    box-shadow: 0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 1.5rem;
    background-color: var(--cdm-principal);
}

.benefits__content p {
    color: var(--cdm-branco);
}

.benefits h3:last-child {
    text-align: center;
}

.benefits h3:last-child span{
    color: var(--cdm-principal);
}

.benefits__conclusao {
    margin: 2rem 0;
}

@media screen and (min-width: 768px) {
    .benefits {
        font-size: 1rem;
        margin: 1.5rem 5rem;
    }

    .benefits__title {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .benefits__title h1 {
        font-size: 1.9rem;
        line-height: 1.9rem;
        margin: 1rem 0;
        align-items: initial;
    }

    .benefits__title h1 span {
        font-size: 2rem;
    }

    .benefits h3 {
        font-size: 1.3rem;
        margin-top: 0rem;
        text-decoration-color: var(--cdm-principal);
    }
}

@media screen and (min-width: 1025px) {

    .benefits__title h1 {
        font-size: 2rem;
        line-height: 2rem;
        margin: 1rem;
    }

    .benefits__title h1 span {
        font-size: 2rem;
    }

    .benefits__content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 15rem;
        width: 100%;
        position: relative;
        align-items: center;
        border-right: none;
        border-bottom: none;
        border-top: none;
        box-shadow: none;
        margin-bottom: 0;
        background-color: transparent;
    }

    .benefits__content p {
        color: var(--cdm-secundaria);
    }

    .benefits_labs {
        background-image: url('../../../../assets//img-main/benefits_container_labs.png');

    }

    .benefits_amplarede {
        background-image: url('../../../../assets//img-main/benefits_container_rede.png');
    }

    .benefits_preco {
        background-image: url('../../../../assets//img-main/benefits_container_preco.png');
    }

    .benefits_reclame {
        background-image: url('../../../../assets//img-main/benefits_container_reclame.png');
    }

    .benefits_labs,
    .benefits_amplarede ,
    .benefits_preco,
    .benefits_reclame {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .benefits h3 {
        font-size: 1.3rem;
        margin-top: 1rem;
        color: var(--cdm-principal);
    }

}

@media screen and (min-width: 1440px) {
    .benefits {
        margin: 3rem 15rem;
        font-size: 1.1rem;
        line-height: 1.4rem;
    }

    .benefits__title h1 {
        font-size: 2.9rem;
        line-height: 2.9rem;
        margin: 1.5rem;
    }

    .benefits__title h1 span {
        font-size: 3rem;
    }

    .benefits h3 {
        font-size: 1.7rem;
        line-height: 1.7rem;
    }

}