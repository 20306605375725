/* COBERTURA */

.cobertura__container {
    display: flex;
    flex-direction: column;
    padding: 0 0 2rem 0;
    background-color: var(--cdm-branco);
}

.cobertura__titulo {
    background-color: var(--cdm-secundaria);
    margin-bottom: 2rem;
    padding: 2rem 0 1rem 0;
}

.cobertura__titulo h2 {
    color: var(--cdm-branco);
    font-size: 1.5rem;
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: 0.3rem;
    text-decoration-color: var(--cdm-principal);
    text-align: center;
    line-height: 1.8rem;
    margin-bottom: 2rem;
}

.rede__container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 0 1.5rem;
    gap: 2rem;
    justify-content: center;
    color: var(--cdm-branco);
}

.redes {
    box-shadow: var(--cdm-border-colored);
    border-radius: 0.5rem;
    padding: 1rem 0;
    flex: 1 1 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--cdm-principal);
}

.redes__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 0.5rem; */
}

.redes__info img {
    max-width: 15%;
}

.redes__info-localizacao {
text-align: center;
margin: 0.5rem;
}

.redes__info p {
    font-weight: 600;
    font-size: 1rem;
}

.redes__info p:last-child {
    font-size: 0.9rem;
    font-weight: 400;
}

.redes__info-texto {
    text-align: center;
    line-height: 1.1;
display: flex;
flex-direction: column;
gap: 0.3rem;
}

.redes__info-texto p:first-of-type {
    font-weight: 500;
    font-size: 1rem;
}

.redes__info-texto p:last-child {
    font-weight: 700;
    font-size: 1rem;
}

.redes__info-texto p {
    font-size: 0.9rem;
}

.redes__info-texto .redes__info-servicos {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    margin: 0.2rem 0;
}

.redes__info-texto .redes__info-servicos p {
    font-size: 0.8rem;
    font-weight: 400;
    border: 1px solid var(--cdm-branco);
    padding: 0.1rem 0.3rem;
    border-radius: 30px;
}

@media screen and (min-width: 768px) {
    .cobertura__titulo h2 {
        font-size: 1.7rem;
        text-underline-offset: 0.4rem;
    }

    .redes {
        flex: 1 1 45%;
    }

    .rede__container {
        padding: 0 5rem;
    }
}

@media screen and (min-width: 1025px) {
    .cobertura__titulo h2 {
        font-size: 2.3rem;
        text-underline-offset: 0.4rem;
        margin: 1rem 0 1rem 0;
    }

    .redes {
        flex: 1 1 30%;
    }
}

@media screen and (min-width: 1440px) {
    .redes {
        flex: 1 1 22%;
    }
}