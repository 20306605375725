/* Consultas.css */

.consultas {
    padding: 1.5rem;
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--cdm-principal);
    min-height: 91vh;
}

.consultas__container {
    padding: 0 1.5rem 1.5rem 1.5rem;
    border-right: 3px solid var(--cdm-branco);
    border-bottom: 3px solid var(--cdm-branco);
    border-top: 3px solid var(--cdm-branco);
    border-radius: 1rem;
    box-shadow: 0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 1.5rem;
    background-color: var(--cdm-secundaria);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.consultas__titulo {
    font-size: 1.5rem;
    font-weight: 700;
    padding: 1rem 0;
    color: var(--cdm-branco);
    justify-content: center;
    align-items: center;
    text-align: center;
}

.consultas__titulo .div-separate {
    width: 100%;
    text-align: center;
    margin: 20px 0;
    padding-top: 0.5rem;
}

.consultas__titulo .style-div-separate::before,
.consultas__titulo .style-div-separate::after {
    content: '';
    display: flex;
    width: 5rem;
    height: 0.15rem;
    margin: 0 auto;
    background-color: var(--cdm-principal);
}

.consultas__descricao {
    color: var(--cdm-branco);
    line-height: 1.3;
}

.consultas__descricao strong {
    color: var(--cdm-principal);
    font-weight: 500;
}

.consultas__contato__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.consultas__contato {
    align-items: center;
    text-align: center;
}

.consultas__contato .div-separate {
    width: 100%;
    text-align: center;
    margin: 20px 0;
    padding-top: 0.5rem;
}

.consultas__contato .style-div-separate::before,
.consultas__contato .style-div-separate::after {
    content: '';
    display: flex;
    width: 5rem;
    height: 0.15rem;
    margin: 0 auto;
    background-color: var(--cdm-branco);
}

.consultas__contato p,
.consultas__contato__numero h3 {
    font-weight: 700;
}

.consultas__contato p {
    color: var(--cdm-principal);
    margin-top: 0.3rem;
    font-size: 1.2rem;
}

.consultas__contato__numero {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.consultas__contato__numero h3 {
    color: var(--cdm-branco);
    font-size: 1.5rem;
}

.consultas__contato__numero img {
    width: 42px;
}

.no-copy {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.consultas__imagem img {
    display: none;
}

.consultas__imagem2 img {
    width: 350px;
}


@media screen and (min-width: 768px) {
    .consultas__container {
        margin: 1rem 5rem;
        padding: 1rem 3rem 2rem 3rem;
    }

    .consultas__contato__container {
        flex-direction: row;
        gap: 2rem;
    }

    .consultas__contato__numero {
        flex-direction: column;
    }

    .consultas__contato p {
        font-size: 1.5;
    }

    .consultas__contato h3 {
        font-size: 2rem;
    }

    

    .consultas__contato .style-div-separate::before,
    .consultas__contato .style-div-separate::after {
        display: none;
    }

    .consultas__imagem img {
        width: 200px;
    }

    .consultas__contato__numero img {
        width: 50px;
    }
}

@media screen and (min-width: 1025px) {
    .consultas {
        background-color: transparent;
        background-image: url(../../../assets/img-consultas/bg-consultas.png);
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: 9.5rem;
        height: auto;
        padding: 0 5rem;
    }

    .consultas__container {
        border-right: none;
        border-bottom: none;
        border-top: none;
        box-shadow: none;
        margin: 0;
        padding: 0;
        background-color: transparent;
    }
    
    .consultas__titulo,
    .consultas__descricao,
    .consultas__contato h3 {
        color: var(--cdm-secundaria);
    }

    .consultas__titulo {
        padding: 0;
        font-size: 1.7rem;
    }

    .consultas__imagem img {
        width: 350px;
        
    }

    .consultas__contato .div-separate {
        display: none;
    }

}

@media screen and (min-width: 1440px) {
    .consultas {
        padding: 0 20rem;
    }
    .consultas__container{
        gap: 0.5rem;
    }

    .consultas__titulo {
        padding: 0;
        font-size: 2rem;
    }
}


@media screen and (min-width: 1920px) {
    .consultas {
        padding: 0 30rem;
    }

    .consultas__container {
        gap: 2rem;
    }
}