@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


:root {
    /* COLORS */
    --cdm-branco: #F2F2F2;
    --cdm-cinza-shadow: #e2e2e2;
    --cdm-preto: #33414c;
    --cdm-cinza: #666;
    --cdm-cinza-claro: #495964;
    --cdm-principal: #ed3237;
    --cdm-secundaria: #495964;
    --cdm-terciaria: #0D0D0D;
    --cdm-vermelho: #F20505;
    --cdm-botao-hover: #fd2740;
    --cdm-shadow: 0px 5px 6px rgba(0, 0, 0, 0.1);
    --cdm-shadow-acima: 0px -5px 6px rgba(0, 0, 0, 0.1);
    --cdm-wpp-title-dark: #23a21c;
    --cdm-wpp-title-light: #8ff787;
    --cdm-wpp-gradient: linear-gradient(45deg, var(--cdm-wpp-title-dark), var(--cdm-wpp-title-light));
    --cdm-wpp-gradient-invert: linear-gradient(45deg, var(--cdm-wpp-title-light), var(--cdm-wpp-title-dark));
    --cdm-border-colored: 3px 3px 0px 0px var(--cdm-secundaria);
    --cdm-form-confirmation: #4CAF50;
    --cdm-botao-wpp: #25d366;
    --cdm-bg-benefits: #8B8C8C;
    --cdm-principal-rgb: 255, 74, 95;
    --cdm-opacity: 0.5;
    --cdm-cinza-background: #f1f1f3;


    /* FONTES */
    --fonte-principal: "Roboto", sans-serif;
}

body {

    font-family: var(--fonte-principal);
    color: var(--cdm-secundaria);
    font-size: 1rem;
    overflow-x: hidden;

}